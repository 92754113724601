@font-face {
    font-family: Soho Std Regular;
    src: url("SohoStdRegular.otf") format("opentype");
}
:root {
    --uon-sport-green: #065434;
    --uon-sport-gold: #DEB408;
}

.navbar{
    background-color: var(--uon-sport-gold) !important;

    .navbar-nav{
        .nav-link{
            color: white !important;
        }
        .nav-link:hover, .nav-link.active{
            color: var(--uon-sport-green) !important;
        }

    }

}

.nav-pills{
    .nav-link{
        color: var(--uon-sport-green) !important;
    }
    .nav-link:hover, .nav-link.active{
        color: var(--uon-sport-gold) !important;
        background-color: var(--uon-sport-green) !important;
    }
    .nav-item{
        color: var(--uon-sport-green) !important;
    }
    .nav-item:hover, .nav-item.active{
        color: var(--uon-sport-gold) !important;
    }

}


.dropdown-menu{
    background-color: var(--uon-sport-green) !important;
    .dropdown-item{
        color: white !important;
    }
    .dropdown-item:hover{
        color: var(--uon-sport-gold) !important;
        background-color: var(--uon-sport-green) !important;
    }
}

.hero-title{
    h1{
        color: var(--uon-sport-gold) !important;
    }
}

@media (min-width: 992px) {
    .top-lg-25{
        top: 25%;
    }
    .login-color{
        border-top-right-radius: .5rem !important;
        border-bottom-left-radius: 0 !important;
    }
}

@media (min-width: 576px) {
    .top-sm-25 {
        top: 25%;
    }
    .login-color{
        border-top-right-radius: 0;
        border-bottom-left-radius: .5rem;
    }
}

@media (min-width: 768px) {
    .top-md-50{
        top: 50%;
    }
}


.btn-uongreen{
    --bs-btn-color: #fff;
    --bs-btn-bg: var(--uon-sport-green) !important;
    --bs-btn-border-color: var(--uon-sport-green) !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--uon-sport-gold) !important;
    --bs-btn-hover-border-color: var(--uon-sport-green) !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-bg: var(--uon-sport-gold) !important;
    --bs-btn-active-border-color: var(--uon-sport-gold) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--uon-sport-green) !important;
    --bs-btn-disabled-border-color: var(--uon-sport-green) !important;
}

.btn-uongreen-outline{
    --bs-btn-color: var(--uon-sport-green) !important;;
    --bs-btn-border-color: var(--uon-sport-green) !important;
    --bs-btn-hover-color: #fff;
    --bs-btn-hover-bg: var(--uon-sport-gold) !important;
    --bs-btn-hover-border-color: var(--uon-sport-green) !important;
    --bs-btn-focus-shadow-rgb: 49, 132, 253;
    --bs-btn-active-color: #fff;
    --bs-btn-active-border-color: var(--uon-sport-gold) !important;
    --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
    --bs-btn-disabled-color: #fff;
    --bs-btn-disabled-bg: var(--uon-sport-green) !important;
    --bs-btn-disabled-border-color: var(--uon-sport-green) !important;
}

.text-uon-green{
    color: var(--uon-sport-green) !important;
}

.bg-uon-green{
    background-color: var(--uon-sport-green) !important;
}

.border-uon-green{
    border-color: var(--uon-sport-green) !important;
}

.text-uon-gold{
    color: var(--uon-sport-gold) !important;
}

.bg-uon-gold{
    background-color: var(--uon-sport-gold) !important;
}

.border-uon-gold{
    border-color: var(--uon-sport-gold) !important;
}

.font-uon{
    font-family: 'Soho Std Regular', sans-serif !important;
}

/* Grow Shadow */
.hvr-grow-shadow {
    -webkit-transform: perspective(1px) translateZ(0);
    transform: perspective(1px) translateZ(0);
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-transition-duration: 0.3s;
    transition-duration: 0.3s;
    -webkit-transition-property: box-shadow, transform;
    transition-property: box-shadow, transform;
}
.hvr-grow-shadow:hover{
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
    -webkit-transform: scale(1.01);
    transform: scale(1.01);
}

.hvr-box-shadow{
    transition: hvr-box-shadow .3s;
}

.hvr-box-shadow:hover{
    box-shadow: 0 0 11px rgba(33,33,33,.2);
}

.login-color{
    background-color: var(--uon-sport-gold) !important;
    border-bottom-right-radius: .5rem;
    filter: blur(0px);
}

.login-background{
    background-image: url("/img/login_bg.jpg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
}

.deal-logo{
    width: 10em;
    height: 10em;
}